<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div class="profile-hub-import" v-else>
      <UiBanner :title="$t('profilehub.import.title')" :subtitle="$t('profilehub.import.subtitle')" large />
      <UiContainer large with-actions v-if="!success">
        <UiTitle :title="$t('profilehub.import.global.title')" centered />
        <UiSubtitle centered>
          {{ $t('profilehub.import.global.subtitle') }}
          <a @click.stop="openTemplateModal">{{ $t('profilehub.import.global.link') }}</a>
        </UiSubtitle>

        <UiInputFile :file="importFile" :file-formats="fileFormats.sheet" :uploading="uploading" @change="checkFile" />

        <div class="profile-hub-import__file-conditions">
          <UiTitle :title="$t('profilehub.import.global.fileValidation.title')" small />
          <ul class="profile-hub-import__file-conditions__list">
            <li
              v-for="(value, key, idx) in fileValidation"
              :key="`fileValidation${idx}`"
              class="profile-hub-import__file-conditions__list__item"
              :class="{
                'error--text': fileError(key),
                'success--text': fileSucess(key),
              }"
            >
              <div class="tw-flex tw-items-center tw-gap-2">
                <v-icon v-if="!importFile">{{ icons.mdiArrowRightThinCircleOutline }}</v-icon>
                <v-icon v-else-if="fileError(key)" color="error">{{ icons.mdiCloseCircleOutline }}</v-icon>
                <v-icon v-else-if="fileSucess(key)" color="success">{{ icons.mdiCheckCircleOutline }}</v-icon>
                <span>{{ $t(`profilehub.import.global.fileValidation.${key}`) }}</span>
              </div>
            </li>
          </ul>
        </div>

        <div class="profile-hub-import__confirm">
          <UiTitle :title="$t('profilehub.import.global.confirm.title')" small />
          <v-checkbox
            v-model="confirm.optin"
            :label="$t('profilehub.import.global.confirm.optin')"
            class="profile-hub-import__confirm__item"
            hide-details
          >
          </v-checkbox>
          <v-checkbox
            v-model="confirm.list"
            :label="$t('profilehub.import.global.confirm.list')"
            class="profile-hub-import__confirm__item"
            hide-details
          >
          </v-checkbox>
        </div>

        <div class="profile-hub-import__data-conditions">
          <UiTitle small>
            <span>{{ $t('profilehub.import.global.dataValidation.title') }}</span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="tw-ml-2" v-bind="attrs" v-on="on">{{ icons.mdiInformation }}</v-icon>
              </template>
              <span>{{ $t('profilehub.import.global.dataValidation.tooltip') }}</span>
            </v-tooltip>
          </UiTitle>

          <ul class="profile-hub-import__data-conditions__list">
            <li
              v-for="(value, key, idx) in dataValidation"
              :key="`fileValidation${idx}`"
              class="profile-hub-import__data-conditions__list__item"
              :class="{
                'error--text': dataError(key),
                'success--text': dataSuccess(key),
              }"
            >
              <div class="tw-flex tw-items-center tw-gap-2">
                <v-icon v-if="importFile && uploading" class="tw-animate-spin">{{ icons.mdiLoading }}</v-icon>
                <v-icon v-else-if="dataError(key)" color="error">{{ icons.mdiCloseCircleOutline }}</v-icon>
                <v-icon v-else-if="dataSuccess(key)" color="success">{{ icons.mdiCheckCircleOutline }}</v-icon>
                <v-icon v-else>{{ icons.mdiArrowRightThinCircleOutline }}</v-icon>
                <span>{{ $t(`profilehub.import.global.dataValidation.${key}`) }}</span>
              </div>
              <div v-if="errorCode === 'columns_useless' && errorData && key === 'columnMatch'" class="tw-pl-8">
                <div class="tw-flex tw-items-center tw-gap-2 tw-text-sm" v-if="errorData.mandatory.length">
                  <v-icon color="error" small>{{ icons.mdiArrowRightThin }}</v-icon>
                  <span>
                    {{ $tc('profilehub.import.global.dataValidation.mandatory', errorData.mandatory.length) }}
                    <template v-for="(column, idx) in errorData.mandatory">
                      <template v-if="idx > 0">&nbsp;</template>
                      <code :key="`mandatory${idx}`">{{ column }}</code>
                    </template>
                  </span>
                </div>
                <div class="tw-flex tw-items-center tw-gap-2 tw-text-sm" v-if="errorData.useless.length">
                  <v-icon color="error" small>{{ icons.mdiArrowRightThin }}</v-icon>
                  <span>
                    {{ $tc('profilehub.import.global.dataValidation.useless', errorData.useless.length) }}
                    <template v-for="(column, idx) in errorData.useless">
                      <template v-if="idx > 0">&nbsp;</template>
                      <code :key="`useless${idx}`">{{ column }}</code>
                    </template>
                  </span>
                </div>
              </div>
              <div v-if="errorCode === 'file_content_not_good' && errorData && key === 'rowData'" class="tw-pl-8">
                <div class="tw-flex tw-items-center tw-gap-2 tw-text-sm" v-if="errorData.line">
                  <v-icon color="error" small>{{ icons.mdiArrowRightThin }}</v-icon>
                  <span
                    v-dompurify-html="
                      $t('profilehub.import.global.dataValidation.lineNumber', { line: errorData.line + 1 })
                    "
                  >
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <UiActions large centered>
          <v-btn
            type="submit"
            rounded
            color="primary"
            :disabled="uploadDisabled"
            :loading="uploading"
            @click="startUpload"
          >
            {{ $t('profilehub.import.global.cta.upload') }}
          </v-btn>
        </UiActions>
      </UiContainer>
      <UiContainer large v-else-if="success" align-center justify-center>
        <v-icon size="128" color="success">{{ icons.mdiCloudCheckOutline }}</v-icon>
        <UiTitle x-large :title="$t('profilehub.import.success.title')" class="tw-mt-4" />
        <UiSubtitle x-large :subtitle="$t('profilehub.import.success.subtitle')" />
        <a @click="back" class="tw-mt-12">
          {{ $t('profilehub.import.success.cta.back') }}
        </a>
      </UiContainer>
      <ProfileHubImportTemplateGenerator
        ref="templateGenerator"
        :dialog="dialog"
        :criteria="criteria"
        :generating="generating"
        @close="closeModal"
        @generateFile="generateFile"
      />
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  mdiArrowRightThinCircleOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiLoading,
  mdiInformation,
  mdiCloudCheckOutline,
  mdiArrowRightThin,
} from '@mdi/js'
import { fileFormats } from '@/config/fileFormats.config'
import UiContainer from '@/components/UI/Container.vue'
import UiBanner from '@/components/UI/Banner.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiActions from '@/components/UI/Actions.vue'
import UiInputFile from '@/components/UI/input/File.vue'
import ProfileHubImportTemplateGenerator from '@/components/ProfileHub/Import/TemplateGenerator.vue'

const FILE_MAX_SIZE = 10048576
let initialData = () => ({
  importFile: null,
  errorCode: '',
  errorData: null,
  fileUploaded: false,
  fileValidation: {
    format: false,
    size: false,
  },
  dataValidation: {
    lineLimit: 0,
    rowData: 0,
    columnMatch: 0,
  },
  confirm: {
    optin: false,
    list: false,
  },
})

export default {
  name: 'ProfileHubImport',
  components: {
    UiContainer,
    UiBanner,
    UiTitle,
    UiSubtitle,
    UiActions,
    UiInputFile,
    ProfileHubImportTemplateGenerator,
  },
  async created() {
    this.setUpdating(true)
    await this.getCriteria()
    this.setUpdating(false)
  },
  data: () => ({
    dialog: false,
    uploading: false,
    generating: false,
    success: false,
    icons: {
      mdiArrowRightThinCircleOutline,
      mdiCheckCircleOutline,
      mdiCloseCircleOutline,
      mdiLoading,
      mdiInformation,
      mdiCloudCheckOutline,
      mdiArrowRightThin,
    },
    fileFormats,
    ...initialData(),
  }),
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      criteria: state => state.profilehub.criteria,
    }),
    uploadDisabled() {
      return (
        this.importFile === null ||
        !this.confirm.optin ||
        !this.confirm.list ||
        this.uploading ||
        (this.importFile !== null && (!this.fileValidation.format || !this.fileValidation.size))
      )
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      sendFile: 'profilehub/sendFile',
      getCriteria: 'profilehub/getCriteria',
      getTemplate: 'profilehub/getTemplate',
      setAlert: 'backoffice/setAlert',
    }),
    fileSucess(key) {
      return this.importFile && this.fileValidation[key]
    },
    fileError(key) {
      return this.importFile && !this.fileValidation[key]
    },
    dataSuccess(key) {
      return this.importFile && this.fileUploaded && this.dataValidation[key] === 1
    },
    dataError(key) {
      return this.importFile && this.fileUploaded && this.dataValidation[key] === -1
    },
    checkFile(file) {
      this.resetData()
      const extension = file.name.split('.').pop()
      this.fileValidation.format = fileFormats.sheet.includes(extension)
      this.fileValidation.size = file.size < FILE_MAX_SIZE
      this.importFile = file
    },
    openTemplateModal() {
      this.dialog = true
    },
    async generateFile(data) {
      this.generating = true
      try {
        await this.getTemplate(data)
        this.generating = false
        this.dialog = false
        this.$refs.templateGenerator.resetData()
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
        this.generating = false
      }
    },
    async startUpload() {
      this.uploading = true

      let formData = new FormData()
      formData.append('file', this.importFile)

      try {
        await this.sendFile(formData)
        this.dataValidation = {
          lineLimit: 1,
          rowData: 1,
          columnMatch: 1,
        }
        this.fileUploaded = true
        this.uploading = false
        this.success = true
        this.resetData()
      } catch ({ data }) {
        this.errorCode = data.code
        this.errorData = data.dataError
        switch (this.errorCode) {
          case 'file_extension_not_good':
            this.fileValidation.format = false
            break
          case 'file_size_too_big':
            this.fileValidation.size = false
            break
          case 'file_too_big':
            this.dataValidation = {
              lineLimit: -1,
              rowData: 0,
              columnMatch: 0,
            }
            break
          case 'file_content_not_good':
            this.dataValidation = {
              lineLimit: 1,
              rowData: -1,
              columnMatch: 0,
            }
            break
          case 'columns_useless':
            this.dataValidation = {
              lineLimit: 1,
              rowData: 1,
              columnMatch: -1,
            }
            break
          default:
            this.setAlert({
              color: 'error',
              text: this.$t('error.notification.default'),
            })
        }
        this.fileUploaded = true
        this.uploading = false
      }
    },
    resetData() {
      Object.assign(this.$data, initialData())
    },
    back() {
      this.success = false
    },
    closeModal() {
      this.dialog = false
      this.$refs.templateGenerator.resetData()
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-hub-import {
  &__file-conditions,
  &__data-conditions {
    @apply tw-mt-6;

    &__list {
      @apply tw-mt-2 tw-list-none tw-m-0 tw-p-0;

      &__item {
        @apply tw-mt-1;

        &:first-child {
          @apply tw-mt-0;
        }
      }
    }
  }

  &__data-conditions {
    @apply tw-mb-6;
  }

  &__confirm {
    @apply tw-mt-6;

    &__item {
      @apply tw-mt-2 tw-p-0;

      &:first-child {
        @apply tw-mt-0;
      }
    }
  }
}
</style>
