<template>
  <UiDialog :dialog="dialog" scrollable :max-width="700" @close="$emit('close')">
    <template v-slot:header>
      <UiTitle :title="$t('profilehub.import.modal.title')" />
      <UiSubtitle :subtitle="$t('profilehub.import.modal.subtitle')" />
    </template>
    <template v-slot:body>
      <div class="tw-px-4 tw-py-6 sm:tw-px-6">
        <section class="profile-hub-import-template-generator__card__body__section">
          <UiTitle :title="$t('profilehub.import.modal.settings.title')" small />
          <UiSubtitle :subtitle="$t('profilehub.import.modal.settings.subtitle')" />

          <div class="profile-hub-import-template-generator__card__body__section__grid">
            <div>
              <UiLabel id="name" :label="$t('profilehub.import.modal.settings.label.name')" />
              <v-text-field
                v-model.trim="name"
                id="name"
                solo
                flat
                outlined
                :placeholder="$t('profilehub.import.modal.settings.placeholder.name')"
                hide-details
              />
            </div>
            <div>
              <UiLabel id="format" :label="$t('profilehub.import.modal.settings.label.format')" />
              <v-select
                v-model="format"
                id="format"
                solo
                flat
                outlined
                :items="fileFormats"
                item-value="value"
                :placeholder="$t('profilehub.import.modal.settings.placeholder.format')"
                hide-details
              />
            </div>
          </div>
        </section>

        <section class="profile-hub-import-template-generator__card__body__section">
          <UiTitle :title="$t('profilehub.import.modal.content.title')" small />
          <UiSubtitle>
            <span v-dompurify-html="$t('profilehub.import.modal.content.subtitle')"></span>
            <template v-for="(column, idx) in defaultCriteria">
              <template v-if="idx > 0">&nbsp;</template>
              <code :key="`column${idx}`">{{ column }}</code>
            </template>
          </UiSubtitle>

          <v-checkbox
            v-model="allCriteria"
            :label="$t('profilehub.import.modal.content.label.allCriteria')"
            class="tw-mt-6 tw-p-0"
            @change="toggleAllCriteria"
            hide-details
          >
          </v-checkbox>

          <UiLabel id="criteria" :label="$t('profilehub.import.modal.content.label.criteria')" class="tw-mt-3" />
          <v-autocomplete
            v-model="selectedCriteria"
            solo
            flat
            outlined
            id="criteria"
            :items="criteria"
            item-disabled="required"
            item-text="label"
            item-value="label"
            :label="$t('profilehub.import.modal.content.placeholder.criteria')"
            :placeholder="$t('profilehub.import.modal.content.placeholder.criteria')"
            hide-details
            chips
            :clearable="canBeCleared"
            deletable-chips
            multiple
            :disabled="allCriteria"
            @click:clear="resetCriteria"
          />
        </section>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn type="submit" rounded text @click="$emit('close')">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-spacer />

      <v-btn type="submit" rounded color="primary" @click="generateFile" :disabled="generating" :loading="generating">
        {{ $t('profilehub.import.modal.cta.generate') }}
      </v-btn>
    </template>
  </UiDialog>
</template>

<script>
import UiDialog from '@/components/UI/Dialog.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiLabel from '@/components/UI/Label.vue'
import { stringToSlug } from '@/utils/utilities.util'

let initialData = () => ({
  name: '',
  format: '',
  allCriteria: false,
  selectedCriteria: [],
  fileFormats: [
    {
      text: 'Excel (.xlsx)',
      value: 'xlsx',
    },
    {
      text: 'Comma-separated values (.csv)',
      value: 'csv',
    },
    {
      text: 'Tab-separated values (.tsv)',
      value: 'tsv',
    },
  ],
})

export default {
  name: 'ProfileHubImportTemplateGenerator',
  components: {
    UiDialog,
    UiTitle,
    UiSubtitle,
    UiLabel,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    generating: {
      type: Boolean,
      required: true,
    },
    criteria: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  mounted() {
    this.selectedCriteria = this.defaultCriteria
  },
  data: () => initialData(),
  computed: {
    defaultCriteria() {
      return this.criteria.filter(c => c.required).map(c => c.label)
    },
    canBeCleared() {
      return this.selectedCriteria.some(c => !this.defaultCriteria.includes(c))
    },
  },
  methods: {
    toggleAllCriteria() {
      if (this.allCriteria) {
        this.selectedCriteria = this.criteria.map(c => c.label)
      } else {
        this.resetCriteria()
      }
    },
    generateFile() {
      this.$emit('generateFile', {
        criterias: this.selectedCriteria,
        filename: stringToSlug(this.name),
        extension: this.format,
      })
    },
    resetCriteria() {
      this.$nextTick(() => {
        this.selectedCriteria = this.defaultCriteria
      })
    },
    resetData() {
      Object.assign(this.$data, initialData())
      this.selectedCriteria = this.defaultCriteria
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-hub-import-template-generator {
  &__card {
    @apply tw-rounded-2xl;

    &__header,
    &__actions {
      @apply tw-p-4 sm:tw-px-6;
    }

    &__body {
      @apply tw-h-full tw-overflow-y-auto tw-p-4 sm:tw-p-6;

      &__section {
        @apply tw-mt-12;

        &:first-child {
          @apply tw-mt-0;
        }

        &__grid {
          @apply tw-grid sm:tw-grid-cols-2 tw-gap-6 tw-w-full tw-mt-6;
        }
      }
    }

    &__actions {
      @apply tw-flex tw-justify-between;
    }
  }
}
</style>
